export default [
  {
    key: 'betToolId',
    label: 'field.tool',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'tool',
    selectionKey: 'id',
    selectionLabel: 'betToolEn',
  },
  {
    skip: true,
    cols: 6,
  },
  {
    key: 'startTime',
    label: 'field.startTime',
    rules: 'required',
    type: 'time-picker',
  },
  {
    key: 'endTime',
    label: 'field.endTime',
    rules: 'required',
    type: 'time-picker',
  },
  {
    key: 'status',
    label: 'status.active',
    type: 'checkbox',
    cols: 'auto',
  },
]
